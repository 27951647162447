<div class="ng-select-dropdown-container">
    <span class="ng-select-label">
        Select User
        <span *ngIf="required"> * </span>
    </span>

    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline'
        "
        [class.error]="formControl.touched && formControl.errors"
        [class.disabled]="formState === FormState.IDLE || !optionsList.length"
        [matTooltip]="isDropDownAvailable ? null : 'Select Customer Name First'"
        [matTooltipShowDelay]="500"
        [matTooltipPosition]="'before'"
    >
        <ng-select
            #ngSelectRef
            [formControl]="formControl"
            style="z-index: 10"
            [appearance]="'legacy'"
            [class.field-required]="required"
            bindValue="id"
            [items]="optionsList"
            [closeOnSelect]="false"
            [placeholder]="'Select Users'"
            [loading]="showLoading"
            [appendTo]="'body'"
            [loadingText]="'Loading...'"
            dropdownPosition="auto"
            [clearable]="true"
            [multiple]="true"
            [maxSelectedItems]="Infinity"
            [virtualScroll]="true"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-container>
                <ng-template
                    ng-multi-label-tmp
                    let-items="selectedValues"
                    let-clear="clear"
                >
                    <div class="ng-value" *ngIf="formControl.value.length > 0">
                        <span class="ng-value-label"
                            >{{
                                showLoading
                                    ? "Loading..."
                                    : formControl.value.length + " selected"
                            }}
                        </span>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container
                *ngIf="!showLoading && optionsList && optionsList.length"
            >
                <ng-template ng-header-tmp>
                    <div
                        *ngIf="
                            formControl?.value?.length === 0 ||
                            (formControl?.value?.length !== 0 &&
                                getNewUserLength() === formControl.value.length)
                        "
                        (click)="onSelectAll($event, true)"
                        [ngClass]="
                            disableDropdown
                                ? 'disabled'
                                : 'ng-select-option-custom'
                        "
                    >
                        <i class="far fa-square clickable"></i>
                        <span class="common-label"> Select All</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        [ngClass]="
                            disableDropdown
                                ? 'disabled'
                                : 'selected-item ng-select-option-custom'
                        "
                        *ngIf="
                            formControl?.value?.length !== 0 &&
                            formControl.value.length > getNewUserLength() &&
                            formControl?.value?.length !== optionsList.length
                        "
                        (click)="onSelectAll($event, true)"
                    >
                        <i class="fas fa-minus-square clickable"></i>
                        <span class="common-label"> Select All </span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        [ngClass]="
                            disableDropdown
                                ? 'disabled'
                                : 'selected-item ng-select-option-custom'
                        "
                        *ngIf="
                            optionsList.length > 0 &&
                            formControl?.value?.length === optionsList.length
                        "
                        (click)="onSelectAll($event, false)"
                    >
                        <i class="fas fa-check-square clickable"></i
                        ><span class="common-label"> Select All</span>
                    </div>
                </ng-template>
            </ng-container>

            <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
            >
                <div
                    appDisableElement
                    [data]="false"
                    *ngIf="!item$.selected"
                    class="ng-select-option-custom"
                    title="{{ getLabelFromItem(item) }}"
                    (click)="
                        item.existingUserInfo &&
                            selectionChange($event, item, true)
                    "
                >
                    <i class="checkbox-dropdown far fa-square"></i>
                    <span class="common-label">{{
                        getLabelFromItem(item)
                    }}</span>
                    <span
                        *ngIf="item?.existingUserInfo?.root"
                        class="root-label"
                        >Root</span
                    >
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                    title="{{ getLabelFromItem(item) }}"
                    (click)="
                        item.existingUserInfo &&
                            selectionChange($event, item, false)
                    "
                >
                    <i class="checkbox-dropdown fas fa-check-square"></i
                    ><span class="common-label">{{
                        getLabelFromItem(item)
                    }}</span>
                    <span
                        *ngIf="item?.existingUserInfo?.root"
                        class="root-label"
                        >Root</span
                    >
                </div>
            </ng-template>
            <ng-template
                ng-footer-tmp
                *ngIf="!showLoading && optionsList && optionsList.length"
            >
                <div class="add-user-plus-icon" (click)="addNewUserFlow()">
                    <i class="fas fa-plus noSpace"></i>
                    Add new User
                </div>
            </ng-template>
        </ng-select>
        <!-- Handling errors -->
        <ng-container>
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
    </div>
</div>

<ng-template #error>
    <ng-container *ngIf="formControl.touched && formControl.errors">
        <mat-error> User Name is Required </mat-error>
    </ng-container>
</ng-template>
<div class="dropdown-background-layer" *ngIf="showOverlay"></div>