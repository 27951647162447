import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-issue-audit-add-new-user-step-one',
    templateUrl: './issue-audit-add-new-user-step-one.component.html',
    styleUrls: ['./issue-audit-add-new-user-step-one.component.sass'],
})
export class IssueAuditAddNewUserStepOneComponent implements OnInit {
    public userInfoFormGenInput: IFormGeneratorInput = null;
    public userInfoFormGroup: FormGroup;
    private stepData: any;
    private emailValidations = [
        {
            validator: CustomValidators.required,
            errorMessage: 'Email is required',
        },
        {
            validator: CustomValidators.email,
            errorMessage: 'Email is invalid',
        },
    ];

    public nextButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        function: (buttonRef: IButtonData) => {
            this.save(buttonRef);
        },
    };

    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private notificationService: NotificationsService
    ) {}

    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }

        this.settingUpForms();
    }
    private settingUpForms() {
        this.userInfoFormGenInput = {
            formName: 'Create User',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'First Name',
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: this.stepData ? this.stepData.firstName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First name is required',
                        },
                    ],
                    appearance: 'legacy',
                    showLabel: true,
                },
                {
                    label: 'Last Name',
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: this.stepData ? this.stepData.lastName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Last name is required',
                        },
                    ],
                    appearance: 'legacy',
                    showLabel: true,
                },
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'email',
                    value: this.stepData ? this.stepData.email : '',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    validations: this.emailValidations,
                    appearance: 'legacy',
                    showLabel: true,
                },
                {
                    label: 'Password',
                    placeholder: 'Password',
                    name: 'password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    value: this.stepData ? this.stepData.password : '',
                    suffixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'edit',
                        },
                        hoverText: 'Generate password',
                        function: (
                            buttonRef: IButtonData,
                            formGroup: FormGroup
                        ) => {
                            this.generatePassword(formGroup);
                        },
                    },
                    validations: Helper.getPasswordValidators(),
                    appearance: 'legacy',
                    showLabel: true,
                },
                {
                    label: 'Confirm Password',
                    placeholder: 'Confirm password',
                    name: 'confirmPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    value: this.stepData ? this.stepData.password : '',
                    validations: Helper.getPasswordValidators(
                        'equal',
                        'password'
                    ),
                    appearance: 'legacy',
                    showLabel: true,
                },
            ],
        };
    }
    private generatePassword(formGroup: FormGroup) {
        let password = Helper.generateUniqueKey(16);
        password += 'a@B1';
        formGroup.get('password').setValue(password);
        formGroup.get('confirmPassword').setValue(password);
    }

    private save(buttonRef: IButtonData) {
        Helper.markAllFieldAsTouched(this.userInfoFormGroup);
        if (this.userInfoFormGroup.invalid) {
            this.userInfoFormGroup.updateValueAndValidity();
            return;
        }

        const customerId = this.modalInputData.data['customerId'];

        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_USER_ASSESSMENT_ISSUE_DETAILS_VALIDATION.replace(
                '{tenant-id}',
                customerId
            ),
            requestType: RequestType.POST,
            input: this.userInfoFormGroup.getRawValue(),
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (data) => {
                buttonRef.loader = false;
                this.changeDetectorRef.detectChanges();
                this.multiStepFormService.nextStep(this.modalInputData.modalId);
                this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .set(1, this.userInfoFormGroup.getRawValue());
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                this.changeDetectorRef.detectChanges();
                this.notificationService.showSnackBar(
                    error?.error?.message ||
                        'The user already exists. Please use a different email ID',
                    true
                );
            },
        };
        buttonRef.loader = true;
        this.changeDetectorRef.detectChanges();
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }
}
