import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-assessee-listing-modal',
    templateUrl: './assessee-listing-modal.component.html',
    styleUrls: ['./assessee-listing-modal.component.sass'],
})
export class AssesseeListingModalComponent implements OnInit {
    public tableGenInput: ITableGeneratorInput;
    public tableData: [];

    constructor(private modalInjectedData: ModalInjectedData) {
        this.tableData = modalInjectedData.data;
    }

    ngOnInit(): void {
        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT',
            },
            columns: [
                {
                    columnKey: 'userName',
                    columnName: 'User Name',
                    filter: false,
                },
                {
                    columnKey: 'userEmailAddress',
                    columnName: 'Email ID',
                    filter: false,
                },
            ],
        };
    }
}
