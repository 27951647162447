<section class="issue-audit-add-new-user-step-one-form-container">
    <app-form-generator
        *ngIf="userInfoFormGenInput"
        class="tw-flex-1"
        [formGeneratorInput]="userInfoFormGenInput"
        (formGroupRef)="userInfoFormGroup = $event"
    ></app-form-generator>
</section>
<section class="footer">
    <span class="note-text"
        ><span class="bold">Note:</span> The audit will be issued to the new
        user by default and cannot be modified.</span
    >
    <app-button-generator
        class="tw-ml-auto tw-mr-5"
        [buttonGenInput]="nextButtonGenInput"
    ></app-button-generator>
</section>
