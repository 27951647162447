import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
    GridOptions,
    RowEvent,
    RowNode,
    ValueFormatterParams,
    ValueGetterParams
} from 'ag-grid-community';
import moment from 'moment';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { ILoginResponse } from 'src/app/shared/interfaces/api/portlets/ILoginResponse';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ApiResponseCacheService } from 'src/app/shared/services/cache/api-response-cache/api-response-cache.service';
import { AssessmentCacheService } from 'src/app/shared/services/cache/assessment-cache/assessment-cache.service';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { AssessmentMoreInfoModalComponent } from '../../../modal-templates/assessee-view/assessment-more-info-modal/assessment-more-info-modal.component';
import { ReportType } from '../../../modal-templates/assessee-view/assessment-overview/assessment-overview.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
// import { IssueAssessmentStepTwoComponent } from '../issue-assessment-step-two/issue-assessment-step-two.component';
import { RequestType } from './../../../../enums/RequestType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { AssignAssessmentComponent } from './../../../modal-templates/assign-assessment/assign-assessment.component';
import { IssueAssessmentComponent } from './../../../modal-templates/issue-assessment/issue-assessment/issue-assessment.component';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { AssesseeListingModalComponent } from '../../../modal-templates/assessee-view/assessee-listing-modal/assessee-listing-modal.component';
@Component({
    selector: 'app-issue-assessment-listing',
    templateUrl: './issue-assessment-listing.component.html',
    styleUrls: ['./issue-assessment-listing.component.sass']
})
export class IssueAssessmentListingComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    assignAssessmentFormGenInputs: IFormGeneratorInput;
    widgetRef: Widget;
    apiResponse: any;
    agGrid: GridOptions = null;
    assessmentStatus: string[] = [
        'Submitted',
        'Under Evaluation',
        'Approved',
        'Rejected'
    ];
    tableDropdownButtons: IButtonGeneratorInput[];
    actionButtonsDropdown: IButtonGeneratorInput[];
    rowData;
    issueData;
    downloadIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-ellipsis-v',
        extraClass: 'action-icon'
    };
    @ViewChild('downloadMenu') downloadMenu;
    @ViewChild('buttonMenu') buttonMenu;
    modalClose: Symbol;
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private credentialsCacheService: CredentialsCacheService,
        private viewHttpService: ViewHttpService,
        private sessionService: SessionService,
        private globalDataService: GlobalDataService,
        private apiResponseCacheService: ApiResponseCacheService,
        private widgetCreationService: WidgetCreationService,
        private userDataCacheService: UserDataCacheService,
        private assessmentCacheService: AssessmentCacheService,
        private cdRef: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.tableInput = {
            buttons: [
                {
                    buttonName: 'Issue',
                    function: (buttonRef) => {
                        this.issueAssessment();
                    },
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.RAISED
                }
            ],
            widgetIconData: {
                class: 'fas fa-eye',
                type: IconType.FONTAWSOME
            },
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Company Name',
                    columnKey: 'companyName'
                },
                {
                    columnName: 'Assigned To',
                    columnKey: 'assesseUserInfo',
                    filter: false,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'View',
                                function: () => {
                                    this.associatedUsersModal(
                                        Helper.cloneDeep(rowData.data)
                                    );
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                showLoader: false
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                },
                {
                    columnName: 'Issue Date',
                    columnKey: 'issueDate',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.issueDate !== '-') {
                            let date = rowData.data.issueDate;
                            const timeValue = rowData.data.issueDate.includes(
                                ';'
                            )
                                ? rowData.data.issueDate
                                      .split('; ')[1]
                                      .split(' ')[0]
                                : rowData.data.issueDate.split(' ')[1];
                            const timeValueUpdate = moment(
                                timeValue,
                                'HH:mm'
                            ).format('hh:mm');
                            date = date.replace(timeValue, timeValueUpdate);
                            return date;
                        } else {
                            return rowData.data.issueDate;
                        }
                    }
                },
                {
                    columnName: 'Date of Assessment',
                    columnKey: 'auditDueDate',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.auditDueDate !== '-') {
                            let date = rowData.data.auditDueDate;
                            const timeValue =
                                rowData.data.auditDueDate.includes(';')
                                    ? rowData.data.auditDueDate
                                          .split('; ')[1]
                                          .split(' ')[0]
                                    : rowData.data.auditDueDate.split(' ')[1];
                            const timeValueUpdate = moment(
                                timeValue,
                                'HH:mm'
                            ).format('hh:mm');
                            date = date.replace(timeValue, timeValueUpdate);
                            return date;
                        } else {
                            return rowData.data.auditDueDate;
                        }
                    }
                },
                {
                    columnName: 'Assessment Status',
                    columnKey: 'assessmentStatus'
                },
                {
                    columnName: 'Assessment Type',
                    columnKey: 'assessmentType'
                },
                {
                    columnName: 'Issued By',
                    columnKey: 'assessmentIssued'
                },
                {
                    columnName: 'Assessor',
                    columnKey: 'assessor',
                    columnValueGetter: (params: ValueGetterParams) => {
                        if (
                            params.data['moreInfo'] &&
                            params.data['moreInfo']['assessorInfo'] &&
                            params.data['moreInfo']['assessorInfo'].length
                        ) {
                            return params.data['moreInfo']['assessorInfo'].map(
                                (item) => item.userName
                            );
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Assessment Tags',
                    columnKey: 'tags',
                    cellRenderer: (rowData) => {
                        if (rowData.data.tags && rowData.data.tags.length) {
                            return rowData.data.tags
                                .map(
                                    (tag) =>
                                        `&nbsp;<span style="color:${tag.color || tag.colorCode}" >${tag.label || tag.name}</span>`
                                )
                                .join(', ');
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Job Id',
                    columnKey: 'jobId',
                    cellRenderer: (rowData) => {
                        if (rowData?.value) {
                            return rowData?.value;
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    minWidth: 360,
                    buttonGen: true,
                    cellClass: 'grid-cell-data-centred',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const rowEntityData = rowData.data;
                        this.issueData = rowData.data;
                        const status = rowEntityData['assessmentStatus'];
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.issueAssessment(
                                        Helper.cloneDeep(rowData.data)
                                    );
                                },
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                showLoader: false,
                                disable:
                                    rowData.data['disableAllActions'] ||
                                    !rowData.data['canEdit']
                            },
                            {
                                buttonName: 'Evaluate',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (rowData.data['canEvaluate']) {
                                        this.evaluateAssessment(
                                            rowData.data['assessmentId'],
                                            buttonRef
                                        );
                                    } else {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Evaluation can not be conducted.',
                                            true
                                        );
                                    }
                                },
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,
                                disable:
                                    rowData.data['disableAllActions'] ||
                                    !rowData.data['canEvaluate'],
                                customClass:
                                    rowData.data['disableAllActions'] ||
                                    !rowData.data['canEvaluate']
                                        ? 'decrease-opacity'
                                        : 'opacity-1'
                            },
                            {
                                buttonName: '',
                                matMenu: this.buttonMenu,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.initializeButtons(rowData);
                                },
                                buttonColorType: ButtonColorType.DEFAULT,
                                buttonType: ButtonType.ICON,
                                customClass: 'action-menu',
                                showLoader: true,
                                buttonIcon: this.downloadIcon
                            }
                        ];
                        const options: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = options;
                        return rowData;
                    }
                }
            ],
            selection: 'multiple',
            afterResponse: (response: any) => {
                this.apiResponse = response;
                if (response && response.length) {
                    const bulkActions = new Map<string, IAction[]>();
                    bulkActions.set('Bulk Actions', [
                        {
                            message: '',
                            state: ActionState.ENABLED,
                            visibility: ActionVisibility.VISIBLE,
                            actionId: '',
                            function: this.bulkCancelAssessment.bind(this),
                            icon: {
                                type: IconType.FONTAWSOME,
                                class: '',
                                text: 'Void',
                                extraClass: ''
                            }
                        }
                    ]);
                    this.widgetRef.operationalActions.next(bulkActions);
                }
            },
            isRowSelectable: (params) => {
                return (
                    !params.data['disableAllActions'] &&
                    params.data['assessmentStatus'] !==
                        AssessmentStatus.REJECTED &&
                    params.data['assessmentStatus'] !==
                        AssessmentStatus.APPROVED &&
                    params.data['canVoid']
                );
            }
        };
    }
    bulkCancelAssessment() {
        const assessmentData: any[] = [];
        const assessmentIds: any[] = [];
        this.agGrid.api.getSelectedNodes().forEach((rowNode: RowNode) => {
            assessmentData.push(rowNode.data);
        });
        assessmentData.forEach((res) => {
            assessmentIds.push(res['assessmentId']);
        });
        if (assessmentIds.length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select an Assessment to cancel'
            );
            return;
        }
        const apiArgs: IHitApi = {
            url: ApiUrls.CANCEL_ASSESSMENT_BULK,
            input: {
                assessmentDataIds: assessmentIds
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.widgetRef.refreshWidget();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.widgetRef.refreshWidget();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.widgetHttpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    initializeButtons(rowData) {
        this.actionButtonsDropdown = [
            {
                buttonName: 'Download',
                matMenu: this.downloadMenu,
                function: (buttonRef: IButtonGeneratorInput) => {},
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                disable:
                    rowData.data['disableAllActions'] ||
                    !rowData.data['canDownloadReport'],
                customClass: !rowData?.data['canDownloadReport']
                    ? 'decrease-opacity'
                    : 'opacity-1'
            },
            {
                buttonName: 'More Info',
                function: (buttonRef: IButtonGeneratorInput) => {
                    // More Info
                    this.moreInfoModal(Helper.cloneDeep(rowData.data));
                },
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: false
            },
            {
                buttonName: 'Void',
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    const modalData: IConfirmationModal = {
                        modalName: 'Void Assessment',
                        confirmationMessage: `Click on “confirm” to continue.
                        Note: once confirmed, the assessment will be marked as void. You will not be able to access it.`,
                        buttonText: 'Confirm',
                        buttonColorType: ButtonColorType.PRIMARY,
                        hideCancelButton: true,
                        loaderOnExec: true,
                        function: (modalId: Symbol) => {
                            this.cancelAssessment(rowData.data['assessmentId']);
                        },
                        modalWidthVw: 40,
                        modalHeightVh: 30
                    };
                    this.modalClose =
                        this.modalService.openConfirmationModal(modalData);
                },
                disable:
                    rowData.data['disableAllActions'] ||
                    rowData.data['assessmentStatus'] ===
                        AssessmentStatus.REJECTED ||
                    rowData.data['assessmentStatus'] ===
                        AssessmentStatus.APPROVED ||
                    !rowData.data['canVoid'],
                hoverText:
                    rowData.data['assessmentStatus'] ===
                        AssessmentStatus.REJECTED ||
                    rowData.data['assessmentStatus'] ===
                        AssessmentStatus.APPROVED
                        ? 'Assessments that are approved or rejected cannot be marked as void'
                        : ''
            }
        ];
        this.tableDropdownButtons = [
            {
                buttonName: 'Detailed Report',
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    const rowEntityData = rowData?.data;
                    this.widgetCreationService.downloadAssessmentReport(
                        buttonRef,
                        rowEntityData?.assessmentId,
                        rowEntityData.assessmentName,
                        ReportType.DETAILED_REPORT,
                        () => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.notificationService.showSnackBar(
                                'Report successfully downloaded'
                            );
                        },
                        (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.notificationService,
                                error,
                                'Error downloading report'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        }
                    );
                }
            },
            {
                buttonName: 'Summary Report',
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    const rowEntityData = rowData.data;
                    this.widgetCreationService.downloadAssessmentReport(
                        buttonRef,
                        rowEntityData.assessmentId,
                        rowEntityData.assessmentName,
                        ReportType.SUMMARY_REPORT,
                        () => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.notificationService.showSnackBar(
                                'Report successfully downloaded'
                            );
                        },
                        (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.notificationService,
                                error,
                                'Error downloading report'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        }
                    );
                }
            }
        ];
    }
    onGridReady() {
        if (this.agGrid && this.apiResponse && this.apiResponse.length) {
            const index = -1;
            if (index > 0) {
                const assessmentData = this.apiResponse[index];
                this.apiResponse.splice(index, 1);
                this.apiResponse.unshift(assessmentData);
                this.agGrid.api.setRowData(this.apiResponse);
                this.agGrid.api.refreshCells();
            }
        }
    }
    cancelAssessment(id) {
        const apiArgs: IHitApi = {
            url: ApiUrls.CANCEL_ASSESSMENT_BULK,
            input: {
                assessmentDataIds: [id]
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.modalService.closeModal(null, this.modalClose);
                this.widgetRef.refreshWidget();
            },
            errorFunction: (error) => {
                this.modalService.closeModal(null, this.modalClose);
                Helper.showErrorMessage(this.notificationService, error);
                this.widgetRef.refreshWidget();
            }
        };
        apiArgs.url = apiArgs.url.replace('{assessment-id}', id);
        new HitApi(
            apiArgs,
            this.widgetRef.widgetHttpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    issueAssessment(stepData?) {
        const modalData: IModalData = {
            modalName: stepData ? 'Update Assessment' : 'Issue Assessment',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalAutoHeight: true,
            modalWidthVw: stepData ? 50 : 55,
            noStepPadding: true,
            hideSteps: true,
            showConfirmationModal: this.showCloseConfirmationModal.bind(this),
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: IssueAssessmentComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                isEdit: stepData ? true : false,
                                stepData,
                                issueData: this.issueData
                            }
                        }
                    },
                    stepName: 'Step 1'
                },
                // No longer required
                // {
                //     stepData: {
                //         componentToLoad: IssueAssessmentStepTwoComponent,
                //         payload: {
                //             data: {
                //                 widgetRef: this.widgetRef
                //             }
                //         }
                //     },
                //     stepHeightVh: stepData ? 35 : 75,
                //     stepName: 'Step 1'
                // }
            ]
        };
        this.modalService.openModal(modalData);
    }
    moreInfoModal(showInfoData) {
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'More Info',
            modalIcon: null,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noStepPadding: true,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: AssessmentMoreInfoModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                showInfoData: showInfoData
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    private associatedUsersModal(rowData) {
        const modalData: IModalData = {
            modalName: 'Assigned To',
            modalIcon: null,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            noStepPadding: true,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: AssesseeListingModalComponent,
                        payload: {
                            data: rowData?.moreInfo?.assesseUserInfo || [],
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    private assignAssessment(id) {
        const modalArgs: IModalData = {
            modalName: 'Assign Assessment',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: null,
            modalSteps: [
                {
                    stepName: 'Assign Assessment',
                    stepData: {
                        componentToLoad: AssignAssessmentComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                assessmentId: id
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    evaluateAssessment(id, buttonRef: IButtonGeneratorInput) {
        this.globalDataService.fullPageLoader.next(true);
        buttonRef.loader = true;
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSMENT_EVALUATE_API,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.assessmentCacheService.defaultViewId =
                    this.globalDataService.selectedView;
                this.assessmentCacheService.widgetId =
                    this.widgetRef.widgetData.widgetId;
                this.assessmentCacheService.assessmentId = id;
                this.assessmentCacheService.assessmentAuditType =
                    data['auditType'];
                this.userDataCacheService.isAssessorView = true;
                this.apiResponseCacheService.deleteCollection();
                this.credentialsCacheService.masterLoginDiveIn(data);
                this.sessionService.login(
                    data,
                    this.viewHttpService,
                    true,
                    () => {
                        this.widgetRef.notificationsService.showSnackBar(
                            Messages.LOGIN_API_INVALID_RESPONSE,
                            true
                        );
                        this.credentialsCacheService.masterLoginDiveOut();
                    },
                    () => {
                        this.userDataCacheService.isAssesseeView = true;
                    }
                );
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(this.notificationService, error);
                this.widgetRef.refreshWidget();
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace(
            '{widget-id}',
            this.widgetRef.widgetData.widgetId
        );
        apiArgs.url = apiArgs.url.replace('{assessment-id}', id);
        new HitApi(
            apiArgs,
            this.widgetRef.widgetHttpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    userMasterLogin(userData, tenantId, resetFunction, viewId) {
        const apiArgs: IHitApi = {
            url: `${ApiUrls.USER_MASTER_LOGIN}`,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response: ILoginResponse) => {
                this.apiResponseCacheService.deleteCollection();
                this.credentialsCacheService.masterLoginDiveIn(response);
                this.sessionService.login(
                    response,
                    this.viewHttpService,
                    true,
                    () => {
                        this.widgetRef.notificationsService.showSnackBar(
                            Messages.LOGIN_API_INVALID_RESPONSE,
                            true
                        );
                        this.credentialsCacheService.masterLoginDiveOut();
                    }
                );
            },

            errorFunction: (error) => {
                resetFunction();
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    Messages.LOGIN_API_INVALID_RESPONSE
                );
            }
        };
        apiArgs.url = apiArgs.url.replace('{tenant-id}', tenantId);
        apiArgs.url = apiArgs.url.replace('{user-id}', userData['id']);
        apiArgs.url = apiArgs.url.replace('{view-id}', viewId);

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    private showCloseConfirmationModal(issueAssessmentModalId: Symbol) {
        const confirmationModalData: IConfirmationModal = {
            function: (modalId) => {
                this.widgetRef.modalService.closeModal(null, modalId);
                this.widgetRef.modalService.closeModal(null, issueAssessmentModalId);
            },
            modalName: 'Cancel Issue Assessment',
            hideCancelButton : true,
            confirmationMessage: `All the entries added will be lost. Do you wish to proceed ?`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalAutoHeight: true,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData,
                            },
                        },
                    },
                    stepName: 'Confirmation',
                },
            ],
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
enum AssessmentStatus {
    REJECTED = 'Rejected',
    APPROVED = 'Approved'
}
