<div class="tw-relative tw-h-full tw-w-full container">
    <div
        class="tw-cursor-pointer close-button"
        (click)="widgetRef.modalService.closeModal(null, modalData.modalId)"
        appIcon
        [data]="crossIcon"
    ></div>

	<app-vertical-scrolling-tab-group>
		<ng-template appVerticalScrollingTab [tabLabel]="tabsNameToTitleMap.assessee">
			<mat-accordion displayMode="flat" multi>
				<mat-expansion-panel
					#consumptionInfoPanel
					hideToggle="true"
					[expanded]="true"
				>
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ tabsNameToTitleMap.assessee }}
						</mat-panel-title>
						<mat-panel-description>
							<span
								*ngIf="!consumptionInfoPanel.expanded"
								appIcon
								[data]="arrowDownIcon"
							></span>
							<span
								*ngIf="consumptionInfoPanel.expanded"
								appIcon
								[data]="arrowUpIcon"
							></span>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<div class="body-container">
						<app-only-table-generator
							[tableInput]="assesseeInfoTableGenInput"
							[hideControlBar]="true"
							[defaultColDef]="consumptionInfoColDef"
							[tableData]="assesseeInfoTableData"
						></app-only-table-generator>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-template>
		<ng-template appVerticalScrollingTab [tabLabel]="tabsNameToTitleMap.assessmentInformation">
			<mat-accordion displayMode="flat" multi>
				<mat-expansion-panel
					#consumptionInfoPanel
					hideToggle="true"
					[expanded]="true"
				>
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ tabsNameToTitleMap.assessmentInformation }}
						</mat-panel-title>
						<mat-panel-description>
							<span
								*ngIf="!consumptionInfoPanel.expanded"
								appIcon
								[data]="arrowDownIcon"
							></span>
							<span
								*ngIf="consumptionInfoPanel.expanded"
								appIcon
								[data]="arrowUpIcon"
							></span>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<div
						class="body-container"
						*ngIf="
							assessmentInfoTableGenInput &&
							assessmentInfoTableData
						"
					>
						<app-only-table-generator
							[tableInput]="assessmentInfoTableGenInput"
							[hideControlBar]="true"
							[defaultColDef]="consumptionInfoColDef"
							[tableData]="assessmentInfoTableData"
						></app-only-table-generator>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-template>
		<ng-template
			*ngIf="!hideAssessorTab"
			appVerticalScrollingTab
			[tabLabel]="tabsNameToTitleMap.assessor"
		>
			<mat-accordion displayMode="flat" multi>
				<mat-expansion-panel
					#consumptionInfoPanel
					hideToggle="true"
					[expanded]="true"
				>
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ tabsNameToTitleMap.assessor }}
						</mat-panel-title>
						<mat-panel-description>
							<span
								*ngIf="!consumptionInfoPanel.expanded"
								appIcon
								[data]="arrowDownIcon"
							></span>
							<span
								*ngIf="consumptionInfoPanel.expanded"
								appIcon
								[data]="arrowUpIcon"
							></span>
						</mat-panel-description>
					</mat-expansion-panel-header>

                    <div
                        class="body-container"
                        *ngIf="
                            assessorInfoTableGenInput && assessorInfoTableData
                        "
                    >
                        <app-only-table-generator
                            [tableInput]="assessorInfoTableGenInput"
                            [hideControlBar]="true"
                            [defaultColDef]="consumptionInfoColDef"
                            [tableData]="assessorInfoTableData"
                        ></app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
        <ng-template
            *ngIf="!hidePmoTab"
            appVerticalScrollingTab
            [tabLabel]="tabsNameToTitleMap.pmo"
        >
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #consumptionInfoPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ tabsNameToTitleMap.pmo }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div
                        class="body-container"
                        *ngIf="pmoInfoTableGenInput && pmoInfoTableData"
                    >
                        <app-only-table-generator
                            [tableInput]="pmoInfoTableGenInput"
                            [hideControlBar]="true"
                            [defaultColDef]="consumptionInfoColDef"
                            [tableData]="pmoInfoTableData"
                        ></app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
	</app-vertical-scrolling-tab-group>
</div>
